import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../components/seo'
import Title from '../components/title'
import Header from '../components/header'
import Container from '../components/container'
import FeatureBlock from '../components/feature-block'
import Layout from '../layout'
import Image from '../components/image'
import styled from 'styled-components'
import { fluidRange } from 'polished'
import AnimatedArticle from '../components/animated-article'
import AnimatedAside from '../components/animated-aside'
import ContactForm from '../components/contact-form'
import PurpleNotch from '../components/purple-notch'
import P from '../components/paragraph'
import Link from '../components/link'

const LabelStyle = styled.p`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.8em;
  letter-spacing: 0.1em;
  margin-bottom: 10px;
  display: block;
`

const Sidebar = styled.div`
  background-color: ${({ theme }) => theme.color.grey.light};
  color: ${({ theme }) => theme.color.dark};
  z-index: 1;
  position: relative;
  max-width: 280px;
  margin: -300px auto 0;
  ${fluidRange({
       prop: 'padding',
       fromSize: '20px',
       toSize: '50px'
     },
     '300px',
     '1600px'
   )}
`

const ContactPage = () => {
  const { valoremV } = useStaticQuery(graphql`
    query {
      valoremV: file(relativePath: { eq: "valorem-v.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title='Contact'
        description={`
          Contact the Valorem Distribution team directly or via the contact form
          with any questions you may have.
        `}
      />

      <Header />

      <Container wide noPadding='top' css={`
        @media(max-width: ${({ theme }) => theme.breakpoint.large}) {
          padding-bottom: 0;
        }
      `}>
        <FeatureBlock grey={false}>
          <AnimatedArticle onLoad>
            <Title fancy>Contact</Title>
            <div css='max-width: 440px;'>
              <P css='margin-bottom: 3em;'>
                Valorem is a celebration of beauty without boundaries. we take
                pride in being positively original in our thinking and creative.
              </P>
              <ContactForm />
            </div>
          </AnimatedArticle>
          <AnimatedAside>
            <Image
              fluid={valoremV.childImageSharp.fluid}
              notch='topLeft'
              alt='Valorem Distribution contact details'
            />
            <Sidebar>
              <LabelStyle>Location</LabelStyle>
              <p>
                Unit 1<br />
                Quadrant Court<br />
                Crossways Business Park<br />
                Greenhithe<br />
                DA9 9AY
              </p>
              <LabelStyle>Email</LabelStyle>
              <Link href='mailto:sales@valoremdistribution.com'>
                sales@valoremdistribution.com
              </Link>
            </Sidebar>
            <PurpleNotch css='max-width: 600px;'>
              VDL is always on the lookout for talented people with luxury
              experience to join our growing team.  If you are excited by the
              prospect of working in a fast-paced environment please send your
              CV to
              {' '}
              <Link href='mailto:recruitment@valoremdistribution.com'>
                recruitment@valoremdistribution.com
              </Link>
            </PurpleNotch>
          </AnimatedAside>
        </FeatureBlock>
      </Container>
    </Layout>
  )
}

export default ContactPage
