import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { fluidRange } from 'polished'
import { animated, useSpring, config } from 'react-spring'
import useIsInViewport from 'use-is-in-viewport'
import useTrigger from '../hooks/useTrigger'

const StyledContainer = styled(animated.div)`
  position: relative;
  color: ${({ theme }) => theme.color.grey.light};
  margin-top: 40px;
  margin-right: 40px;
  margin-bottom: 50px;

  &:after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 2px solid ${({ theme }) => theme.color.gold};
    left: 20px;
    right: -20px;
    top: 20px;
    bottom: -20px;
  }

  @media(max-width: 700px) {
    margin-right: 0;
    margin-left: 0;

    &:after {
      display: none;
    }
  }
`

const Notch = styled.div`
  background: ${({ theme }) => theme.color.purple};
  box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  clip-path:
    polygon(
      0 0, 0 0,
      calc(100% - 50px) 0%, 100% calc(0% + 50px),
      100% 100%, 100% 100%,
      0 100%, 0 100%
    );
  ${fluidRange({
      prop: 'padding-top',
      fromSize: '30px',
      toSize: '40px'
    },
    '300px',
    '1600px'
  )}
  ${fluidRange({
      prop: 'padding-bottom',
      fromSize: '30px',
      toSize: '40px'
    },
    '300px',
    '1600px'
  )}
  ${fluidRange({
      prop: 'padding-left',
      fromSize: '30px',
      toSize: '50px'
    },
    '300px',
    '1600px'
  )}
  ${fluidRange({
      prop: 'padding-right',
      fromSize: '30px',
      toSize: '60px'
    },
    '300px',
    '1600px'
  )}

  @media(max-width: 700px) {
    display: block;
    clip-path: none;

    & > a {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
`

const Text = styled.p`
  margin: 0;
  ${fluidRange({
      prop: 'font-size',
      fromSize: '14px',
      toSize: '18px'
    },
    '300px',
    '1600px'
  )}
`

const PurpleNotch = ({ children }) => {
  const containerRef = useRef(null)
  const [isInViewport, wrappedContainerRef] = useIsInViewport({
    target: containerRef,
    threshold: 40
  })
  const trigger = useTrigger(isInViewport)

  const springProps = useSpring({
    config: config.molasses,
    from: {
      opacity: '0',
      transform: 'translateY(50px)'
    },
    to: {
      opacity: trigger ? '1' : '0',
      transform: trigger ? 'translateY(0)' : 'translateY(50px)'
    }
  })

  return (
    <div ref={wrappedContainerRef}>
      <StyledContainer style={springProps}>
        <Notch>
          <Text>{children}</Text>
        </Notch>
      </StyledContainer>
    </div>
  )
}

PurpleNotch.propTypes = {
  children: PropTypes.node
}

export default PurpleNotch
