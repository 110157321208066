import React, { useState } from 'react'
import Button from '../components/button'
import styled from 'styled-components'
import useForm from 'react-hook-form'
import { lighten } from 'polished'
import axios from 'axios'

const Field = styled.div`
  display: flex;
  flex-direction: column-reverse;
`

const Label = styled.label`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.8em;
  letter-spacing: 0.1em;
  margin-bottom: 10px;
  display: block;
  margin-top: 2em;
  color: ${({ theme }) => theme.color.dark};

  ${({ optional }) => optional && `
    &:after {
      content: 'Optional';
      float: right;
      font-size: 0.8em;
    }
  `}
`

const Input = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: calc(100% - 40px);
  padding: 20px;
  background-color: #F4F4F4;
  border: 1px solid ${({ error }) => error ? '#FF4D4D' : '#DCDCDC'};

  &:focus {
    outline: none;
    border: 1px solid ${({ error, theme }) =>
      error ? '#FF4D4D' : lighten(0.2, theme.color.primary)
    };
  }

  &:focus + label {
    color: ${({ theme }) => lighten(0.1, theme.color.primary)};
  }
`

const TextArea = styled.textarea`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: calc(100% - 40px);
  padding: 20px;
  background-color: #F4F4F4;
  border: 1px solid ${({ error }) => error ? '#FF4D4D' : '#DCDCDC'};

  &:focus {
    outline: none;
    border: 1px solid ${({ error, theme }) =>
      error ? '#FF4D4D' : lighten(0.2, theme.color.primary)};
  }

  &:focus + label {
    color: ${({ theme }) => lighten(0.1, theme.color.primary)};
  }
`

const Error = styled.div`
  color: #FF4D4D;
  margin-top: 10px;
`

const Success = styled.div`
  color: #00A300;
  margin-top: 10px;
`

const ContactForm = () => {
  const { register, handleSubmit, errors, setError, reset } = useForm()
  const [submitted, setSubmitted] = useState({
    submitted: false,
    error: false,
    loading: false
  })

  const onSubmit = async body => {
    setSubmitted({ submitted: false, error: false, loading: true })
    try {
      await axios(process.env.GATSBY_MAILGUN_API, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
          subject: 'A new website message',
          body
        }
      })
      setSubmitted({ submitted: true, error: false, loading: false })
      reset()
    } catch (err) {
      if (!err.response || typeof err.response.data.errors === 'undefined') {
        return setSubmitted({ submitted: true, error: true, loading: false })
      }

      Object.entries(err.response.data.errors).forEach(error => {
        const errorType = error[0].replace('body.', '')

        setError(errorType, 'notMatch', Object.values(error[1])[0].message
          .replace('body.', ''))
      })

      setSubmitted({ submitted: false, error: true, loading: false })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field>
        <Error>{errors.name && 'Name is required'}</Error>
        <Input
          id='name'
          type='text'
          name='name'
          ref={register({ required: true })}
          error={errors.name}
        />
        <Label htmlFor='name'>Name</Label>
      </Field>

      <Field>
        <Error>{errors.email && 'Email is required'}</Error>
        <Input
          id='email'
          type='email'
          name='email'
          ref={register({ required: true })}
          error={errors.email}
        />
        <Label htmlFor='email'>Email</Label>
      </Field>

      <Field>
        <Error>{errors.number && 'Please enter a valid phone number'}</Error>
        <Input
          id='number'
          type='tel'
          name='number'
          ref={register}
          error={errors.number}
        />
        <Label htmlFor='number' optional>Number</Label>
      </Field>

      <Field>
        <Error>{errors.company && 'Please enter a valid company name'}</Error>
        <Input
          id='company'
          type='text'
          name='company'
          ref={register}
          error={errors.company}
        />
        <Label htmlFor='company' optional>Company</Label>
      </Field>

      <Field>
        <Error>{errors.message && 'Message is required'}</Error>
        <TextArea
          id='message'
          name='message'
          rows='10'
          ref={register({ required: true })}
          error={errors.message}
        />
        <Label htmlFor='message'>Message</Label>
      </Field>

      <Button
        button
        type='submit'
        colour='primary'
        css='margin-top: 3em; min-width: 192px;'
        loading={submitted.loading}
      >
        {submitted.loading
          ? 'Sending...'
          : 'Send Message'
        }
      </Button>

      <div css='margin-top: 3em'>
        {submitted.submitted && submitted.error &&
          <Error>
            <p>
              There was an error sending your message. Please try again or email
              us at <a href='mailto:sales@valoremdistribution.com'>
              sales@valoremdistribution.com</a>
            </p>
          </Error>
        }

        {submitted.submitted && !submitted.error &&
          <Success>
            <p>Thanks for the message, we&apos;ll be in touch soon.</p>
          </Success>
        }
      </div>
    </form>
  )
}

export default ContactForm
