import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Link from '../filters/link'
import { lighten, darken } from 'polished'

const linkStyle = ({ arrow, theme }) => `
  cursor: pointer;
  color: ${lighten(0.1, theme.color.primary)};

  ${arrow ? `
    position: relative;

    &:after {
      display: inline-block;
      content: '\\2192';
      margin-left: 10px;
      transition: margin-left 500ms ease-in-out;
    }

    &:hover:after {
      margin-left: 15px;
    }
  ` : `
    border-bottom: 2px solid ${lighten(0.1, theme.color.primary)};
  `}

  &:hover {
    border-color: ${theme.color.primary};
    color: ${theme.color.primary};
  }

  &:active {
    color: ${darken(0.1, theme.color.primary)};
  }
`
const StyledA = styled.a`${linkStyle}`
const StyledLink = styled(Link)`${linkStyle}`

const LinkComponent = ({ arrow, children, to, href }) => (
  <>
    {href
      ? <StyledA arrow={arrow} href={href}>{children}</StyledA>
      : <StyledLink arrow={arrow} to={to}>{children}</StyledLink>
    }
  </>
)

LinkComponent.defaultProps = {
  arrow: false
}

LinkComponent.propTypes = {
  arrow: PropTypes.bool,
  to: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.node
}

export default LinkComponent
